.sidebar ul li a:hover{
    background-color: rgb(117,117,117);
    border-radius: 5px;
    cursor: pointer;
}

.sidebar ul li:hover a {
    color: white;
}

.active{
    background-color: rgb(117,117,117);
    border-radius: 5px;
    color:white;
}